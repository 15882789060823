import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ArrowLeft} from '@styled-icons/feather/ArrowLeft';
import {ArrowRight} from '@styled-icons/feather/ArrowRight';
import * as Ant from 'antd';

const Constants = {
  types: {
    INNER: '內部',
    OUTER: '外部',
    GSM: '會員大會',
    BM: '理事會',
    SV: '監事會',
    MD: '常務理事會議',
    BOSM: '理監事聯席會議',
  },
};

export default function MeetingDetail(props) {
  const params = qs.parse(props.location.search);
  const [record, setRecord] = React.useState(null);

  React.useState(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const recordResp = await JStorage.fetchOneDocument('Meeting', {
          id: params.id,
        });
        setRecord(recordResp);
      } catch (ex) {
        console.warn('MeetingDetail ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  return (
    <Wrapper>
      <div className="content">
        <Ant.Button
          style={{display: 'flex', alignItems: 'center', paddingLeft: 0}}
          href={'/member/'}
          type="link"
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate('/member/');
          }}>
          <ArrowLeft size={20} />
          <div style={{fontSize: 18, marginLeft: 6}}>返回會員服務</div>
        </Ant.Button>

        {record && (
          <div className="main">
            <header>
              <h3>{Constants.types[record.MTNG_TYPE]}</h3>
              <h2>{record.MTNG_NAME}</h2>
            </header>

            <div className="start-time">
              {record.EFF_DATE?.split('T')[0] || ''}
            </div>

            <div className="details">
              <div className="field">
                <label>主協辦單位</label>
                <div>{record.organizer || '---'}</div>
              </div>
              <div className="field">
                <label>會議說明</label>
                <div>{record.DESCRIPTION || '---'}</div>
              </div>
              <div className="field">
                <label>相關檔案</label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {(Array.isArray(record.files) &&
                    record.files.map((f, idx) => {
                      return (
                        <Ant.Button
                          href={f.url}
                          target="_blank"
                          style={{marginRight: 10, marginBottom: 10}}>
                          {f.title}
                        </Ant.Button>
                      );
                    })) ||
                    '無'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: var(--topNavBarHeight) 20px 80px;
  min-height: calc(100vh - 200px);

  .row {
    display: flex;
    align-items: center;
  }

  & > .content {
    margin: 40px auto;
    max-width: 800px;

    & > .main {
      & > header {
        padding: 20px 0;
        margin-bottom: 10px;
        border-bottom: 5px solid #b49664;
        h2,
        h3 {
          margin: 0;
          padding: 0;
        }
        h3 {
          color: #888;
        }
      }

      .start-time {
        text-align: right;
        color: #888;
        margin-bottom: 10px;
      }

      .details {
        .field {
          font-size: 18px;
          margin-bottom: 20px;

          label {
            font-size: 14px;
            display: block;
            color: #888;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;
